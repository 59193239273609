// export const Base_url = 'https://adminstg-bluewater.clickysoft.app'
// export const Front_url = 'https://stg-bluewater.clickysoft.app'

// export const Base_url = 'https://admin.bluewater.clickysoft.net'
// export const Front_url = 'https://bluewater.clickysoft.net'
export const Base_url = 'https://admin.bluewaterdivetravel.com'
export const Front_url = 'https://staging.bluewaterdivetravel.com'

export const RedisPort='6380'
export const REDIS_HOST = '3.13.237.83'
export const REDIS_PASS="gsumSqsc4xLjL6Xn9WC9fImKR1Mu5+P6rqpWuqapG37cqDMaHZAwNl7vDwWpmwNltCU="
// export const Base_url = 'http://blue-water-admin.test'