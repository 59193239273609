import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from 'next/image'
import Link from 'next/link'
import arrowRight from '..//assets/images/arro-right-white.svg'
import Rates4 from '..//assets/images/Rates4.svg'
import pp1 from '..//assets/images/pp1.png'
import pp2 from '..//assets/images/pp2.png'
import pp3 from '..//assets/images/pp3.png'
import rr1 from '..//assets/images/rr1.png'
import rr2 from '..//assets/images/rr2.png'
import rr3 from '..//assets/images/rr3.png'
import wave from '..//assets/images/wave2.gif'
import Rates3 from '..//assets/images/Rates3.svg'
import arrowLeftBlue from '..//assets/images/arrowLeftBlue.svg'
import arrowRightBlue from '..//assets/images/arrowRightBlue.svg'
import { useState } from 'react'
const NextButton = ({ onClick }) => {
  return (
    // <div className="slick-slider-dots flex items-center gap-[10px] justify-center">
    <button
      onClick={onClick}
      className='slick-prev testimonial-btn-prev slick-prev4 768-991:!hidden  !transform-none !flex items-center justify-center text-white !bg-[#D6E8EF] rounded-full 992-1199:!left-[38%] 1366-1439:!left-[42.5%] !left-[44%] 1440-1599:!left-[42.5%] !top-[96%] before:hidden !w-12 !h-12 z-[1]'
    >
      <Image src={arrowLeftBlue} alt='image' title='image' />
    </button>
    // </div>
  )
}
const PreviousButton = ({ onClick }) => {
  return (
    // <div className="slick-slider-dots flex items-center gap-[10px] justify-center">
    <button
      onClick={onClick}
      className='slick-next slick-next4 testimonial-btn-next 768-991:!hidden  !transform-none order-2 !flex items-center justify-center text-white !bg-[#D6E8EF] rounded-full before:hidden 992-1199:!right-[39%] 1366-1439:!right-[43%] !right-[44%] 1440-1599:!right-[43%] !top-[96%] !w-12 !h-12 z-[1]'
    >
      <Image src={arrowRightBlue} alt='image' title='image' />
    </button>
    // </div>
  )
}
export default function testimonial () {

  const [expandedReviewIds, setExpandedReviewIds] = useState([]);

  const toggleReadMore = (id) => {
    if (expandedReviewIds.includes(id)) {
      setExpandedReviewIds(expandedReviewIds.filter((reviewId) => reviewId !== id));
    } else {
      setExpandedReviewIds([...expandedReviewIds, id]);
    }
  };

  const reviews = [
    {
      name: "Anne Swanson",
      date: "Mar 2024",
      ratingImage: Rates4,
      avatar: "https://lh3.googleusercontent.com/a-/ALV-UjV5Mkq5joPeZniZXb_FaipgBIScVKa5xyTxLKNZq1Ux5BwlHLIX=s40-c-rp-mo-br100",
      review: `I recently returned from a 5 week trip to Australia and Palau including a 10-day liveaboard on the Palau Siren. Laura, my Travel Advisor, was FABULOUS attending to every detail of the trip. The trip and excellent service exceeded my expectations. The diving was excellent with 3+ dives most days in great locations. We experienced lots of mantas, sharks, schools of fish, wrecks, turtles, and beautiful hard and soft coral. I highly recommend Laura and Bluewater Travel for your next diving adventure. Thank you.`
    },
    {
      name: "Deeya McClurkin",
      date: "2 weeks ago",
      ratingImage: Rates4,
      review: `Can’t say enough about Bluewater Travel and our agent, Laura. She answered all the many questions we had in a timely manner after booking the Indo Siren for a February 2024 trip to Raja Ampat. She really came through when the Indo Siren suffered a catastrophic fire, getting us a full refund after originally being told to "wait" while they tried to find another boat. Laura immediately had another, even better, boat lined up, plus 3 days at a land resort so we could keep our flights unchanged. The detailed itinerary provided was very helpful. We are clients for life.`
    },
    {
      name: "B K",
      date: "2 weeks ago",
      ratingImage: Rates4,
      review: `I was looking for a travel agency that specializes in scuba diving trips as there are many little steps to organize for the hotel, food, dives, equipment, and more. It was a great experience to have one dedicated agent to help with all those steps, answer questions, and be involved in the travel plan. I am very pleased with Bluewater Travel in this regard.`
    },
    {
      name: "Kris",
      date: "March 2023 • Couples",
      ratingImage: Rates4,
      review: `Bluewater Travel made booking our liveaboard trip to Raja Ampat super easy and worry-free. Their staff is top-notch and experienced. Anne helped us every step of the way, providing a very helpful itinerary with frequent email updates. It's no doubt that our next liveaboard trip will be booked with Bluewater Travel.`
    },
    {
      name: "Chad Taylor",
      date: "Feb 2024",
      ratingImage: Rates4,
      avatar: "https://lh3.googleusercontent.com/a-/ALV-UjW7pVjKryoJiF1Mu__DMardon1rrZgGI6VF4XpafYc9m4LjGw8=s40-c-rp-mo-br100",
      review: `Best travel company on the planet! Super easy to work with, very detail-oriented, and consistently set you up with the best accommodations in the best diving spots in the world. Just got done with Raja Ampat and Lembeh with Tim Yeo, and I feel so spoiled with how great it was. Exceeded expectations in all regards!`
    },
    {
      name: "Garth Stewart",
      date: "Feb 2024",
      ratingImage: Rates4,
      review: `Honestly, what I should do is leave a one star review and tell everyone to avoid Bluewater like the plague.  Then I won’t have to battle the hordes of other divers in making my future bookings. But, that would be slightly unethical.  So here goes: I have nothing but good things to say about Bluewater Travel and my coordinator Jaime Smith.  Everything was seamless, communication was fairly instantaneous and I had what has probably been the most epic dive trip I’ve ever been on. I fully intend to utilize Bluewater for my future trips to Palau and the Solomon Islands. Seriously the best trip I’ve ever been on and I’ve been diving for twenty plus years.`
    },
    {
      name: "Tika Phillips",
      date: "Feb 2024 • Couples",
      ratingImage: Rates4,
      avatar: "https://lh3.googleusercontent.com/a-/ALV-UjWV3wbvQt08v8rneKRCYYTpAikdB1-I57T_vZhoJXFjwBx2lfU=s40-c-rp-mo-br100",
      review: `I used Bluewater for a Solomon’s island dive trip. It was wonderful. Anne was super helpful. I appreciated all the info and the 1:1 service. I’m going to use them for my next trip!`
    },
    {
      name: "Smeeta Rishi",
      date: "Mar 2024",
      ratingImage: Rates4,
      review: `I have been working with Bluewater Dive Travel for years, initially travelling with their guided underwater photo workshops which I still continue to do as well as individual customized dive travel.  I have worked with many individuals over the years and more recently with Laura on my individual dive travel plans.  So worth it! The guided workshops are in worldclass destinations.  I continue to work on my underwater photography skills from and enjoy all the trip leaders. Laura plans my customized individual dive travel and is terrific - all travel arrangements are thought out and planned including transfers.  This makes me so much more comfortable travelling on my own to international dive destinations.  She and all the other agents at Blue Water are also well informed on dive destinations and I appreciate their input on the diving and photo opportunities I am looking for. Overall, a great experience to work with Blue Water Dive Travel!`
    }
  ];

  const settings = {
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    prevArrow: <NextButton />,
    nextArrow: <PreviousButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <section className='text-black bg-[#F8FCFF] relative 2560:px-[440px] pt-12 pb-24 768-991:pb-16 992-1199:pb-16'>
      <div className='top-img flex items-center justify-center gap-11 relative 320-529:-mt-32 -mt-44 pointer-events-none'>
        <Image
          src={pp1}
          className='320-529:w-[90px]'
          alt='image'
          title='image'
        />
        <Image
          src={pp2}
          className='320-529:w-[90px]'
          alt='image'
          title='image'
        />
        <Image
          src={pp3}
          className='absolute 320-529:w-[110px] top-[50%] left-[51%] translate-x-[-50%] translate-y-[-50%]'
          alt='image'
          title='image'
        />
        <Image
          src={wave}
          className='absolute -bottom-14 w-[70px] h-[20px] object-contain'
          alt='image'
          title='image'
        />
      </div>
      <div className='review-heading w-full flex items-center justify-center pt-20 px-48 2560:px-[440px] 320-529:px-2 768-991:px-6 992-1199:px-8 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 1366-1439:px-16 1200-1300:px-16 relative'>
        <h3 className='text-center font-lato-extrabold text-[40px] 320-529:text-[24px] 320-529:leading-7 768-991:text-[30px] 768-991:leading-8 992-1199:text-[26px] 992-1199:leading-7 1366-1439:text-[30px] 1366-1439:leading-8 1440-1599:text-[32px]  leading-10'>
          Postcards From Our Explorers
        </h3>
        <Link
          href='https://www.google.com/search?q=bluewater+travel&oq=bluewater+travel&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMhIIARAuGEMYrwEYxwEYgAQYigUyDAgCECMYJxiABBiKBTIMCAMQABhDGIAEGIoFMgwIBBAAGEMYgAQYigUyBggFEEUYPDIGCAYQRRhBMgYIBxBFGDzSAQg0MzI3ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x80c2ba3f4a6fcecf:0xa6b9dcd3e2ac0a5e,1'
          target='_blank'
          className='btn-hover-blue absolute 768-991:hidden right-48 1680-1919:right-32 992-1199:right-8 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:right-16 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] 1600-1679:right-28 1440-1599:right-[6.5rem] bg-[#3DABC2] py-3 px-10  text-base text-white rounded-full border-[2px] border-solid border-[#3DABC2] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#3DABC2]'
        >
          All reviews
          <Image src={arrowRight} alt='image' title='image' />
        </Link>
      </div>

      <div className='review-main relative'>
        <div className='review-slider 768-991:pt-0 pt-1 pl-2 pb-10 !select-text !mb-0'>
          <Slider {...settings}>
            {
              reviews.map((review, index)=>{
                return(
                  <div key={review.name}>
                    <div className='review-slide min-h-[220px] 530-767:mx-auto 530-767:min-h-[250px] 320-529:mx-auto 320-529:min-h-[270px] 768-991:ps-2 768-991:flex-col 768-991:min-h-[250px] 1366-1439:ps-3 992-1199:min-h-[260px] 1366-1439:min-h-[230px] w-[95%] my-[60px] border border-solid border-[#D6E8EF] rounded-[10px] px-2 flex'>
                      <div className='review-avatar'>
                        <div className='avatar w-[80px] h-[80px] 768-991:w-[70px] 768-991:h-[70px] 768-991:translate-y-[-20px] bg-[#4A748B] rounded-[20px] flex items-center justify-center translate-y-[-25px] overflow-hidden border-8 border-solid border-[#F8FCFF]'>
                          {
                            review.avatar ? <Image
                            src={review.avatar}
                            className='w-full h-full object-cover'
                            alt='image'
                            title='image'
                            width={50}
                            height={50}
                          /> : <span className='font-poppins-semiBold text-[40px] 768-991:text-[30px] 1366-1439:text-[30px]  leading-10 tracking-[-0.4px] text-white uppercase'>
                          {review.name.charAt(0)}
                        </span>
                          }
                          
                        </div>
                      </div>
                      <div className='review-detail w-[100%] 768-991:pe-0 768-991:pt-0 768-991:-mt-2 py-3 pb-4 px-4 ps-1 1366-1439:pe-0 1366-1439:ps-2 1440-1599:pe-0'>
                        <div className='review-name flex items-center justify-between'>
                          <h5 className='font-poppins-semiBold line-clamp-1 text-lg 1600-1679:text-base 768-991:text-16px 992-1199:text-[16px] 992-1199:w-[50%] 1440-1599:text-[16px] 1366-1439:text-[16px] 1366-1439:leading-[20px]  leading-[22px] 1440-1599:leading-[20px] tracking-[-0.18px] text-black'>
                            {review.name}
                          </h5>
                          <Image src={review.ratingImage} alt='image' title='image' />
                        </div>
                        <h6 className='text-[#B6D6E2] font-poppins-semiBold text-[13px]  leading-6 tracking-[0.13px]'>
                          {review.date}
                        </h6>
                        <p className={`font-poppins-medium text-[14px]  768-991:pt-1 leading-[22px] tracking-[-0.14px] text-black pt-7 overflow-hidden ${expandedReviewIds.includes(index) ? '' : 'line-clamp-5'}`}>
                          {review.review}
                        </p>
                        <button onClick={() => toggleReadMore(index)} className="readmore-btn font-poppins-regular ml-auto me-3 mt-1 !text-[12px] block">
                          {expandedReviewIds.includes(index) ? 'Show Less' : 'Read More'}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    </section>
  )
}
